<template>
    <div>
        <v-card class="mb-5">
            <v-row no-gutters class="my-0">
                <v-col cols="9">
                    <v-card-title>Przegląd/zlecenia</v-card-title>
                    <v-card-subtitle class="pb-2">Wyszukiwarka zleceń. <code>Data od</code> ustawiana jest automatycznie w chwili zalogowania. Jeżeli ją zmienisz to zapamięta tą co wpiszesz.</v-card-subtitle>
                </v-col>
                <v-col align-self="center">
                    <v-card-actions class="justify-end">
                        <v-btn
                            to="/"
                            class="ml-3"
                            dark
                            small>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
            <v-card-text>
                <v-form
                    autocomplete="off"
                    @submit.prevent="actionSearch">
                    <div>
                        <v-row>
                            <v-col cols="12" sm="8" class="py-sm-1">
                                <v-autocomplete
                                    v-model="searchName"
                                    :items="namesList"
                                    label="Nazwa"
                                    dense
                                    outlined
                                    hide-details
                                    clearable
                                    auto-select-first
                                    prepend-icon="mdi-pencil"
                                    aria-autocomplete="none"
                                    ref="searchName"
                                    @keyup.enter="actionSearch"
                                />
                            </v-col>
                            <v-col cols="12" sm="4" class="py-sm-1">
                                <v-text-field
                                    v-model="searchNumber"
                                    label="Numer tel."
                                    prepend-icon="mdi-phone"
                                    dense
                                    hide-details
                                    clearable
                                    auto-select-first
                                    aria-autocomplete="none"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" sm="4" class="py-sm-1">
                                <v-autocomplete
                                    v-model="searchCity"
                                    :items="cityMap"
                                    label="Miasto"
                                    dense
                                    outlined
                                    hide-details
                                    clearable
                                    auto-select-first
                                    prepend-icon="mdi-city"
                                    aria-autocomplete="none"
                                    ref="searchCity"
                                    @keyup.enter="actionSearch"
                                />
                            </v-col>
                            <v-col cols="12" sm="4" class="py-sm-1">
                                <v-autocomplete
                                    v-if="searchCity"
                                    v-model="searchStreet"
                                    :items="streetsMap[searchCity]"
                                    label="Adres"
                                    dense
                                    outlined
                                    hide-details
                                    clearable
                                    auto-select-first
                                    prepend-icon="mdi-home-floor-a"
                                    aria-autocomplete="none"
                                />
                                <v-text-field
                                    v-else
                                    v-model="searchStreet"
                                    label="Adres"
                                    dense
                                    outlined
                                    hide-details
                                    prepend-icon="mdi-home-floor-a"
                                    aria-autocomplete="none"
                                />
                            </v-col>
                            <v-col cols="12" sm="4" class="py-sm-1">
                                <v-text-field
                                    v-model="searchNoHome"
                                    label="Nr. domu"
                                    dense
                                    outlined
                                    hide-details
                                    prepend-icon="mdi-home-floor-1"
                                    aria-autocomplete="none"
                                />
                            </v-col>
                            <v-col cols="12" md="4" class="py-sm-1">
                                <v-text-field
                                    v-model="searchDateFrom"
                                    label="Data od"
                                    v-mask="'XXXX-XX-XX XX:XX'"
                                    hint="Format: YYYY-MM-DD HH:MM"
                                    persistent-hint
                                    dense
                                    clearable
                                    prepend-icon="mdi-calendar-range"
                                    aria-autocomplete="none"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" md="4" class="py-sm-1">
                                <v-text-field
                                    v-model="searchDateTo"
                                    label="Data do"
                                    v-mask="'XXXX-XX-XX XX:XX'"
                                    hint="Format: YYYY-MM-DD HH:MM"
                                    persistent-hint
                                    dense
                                    clearable
                                    prepend-icon="mdi-calendar-range"
                                    aria-autocomplete="none"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" sm="4" class="py-sm-1">
                                <v-autocomplete
                                    label="Kierowca"
                                    :items="listDriver"
                                    v-model="driverID"
                                    clearable
                                    dense
                                    hide-details
                                    auto-select-first
                                    item-text="driver_number"
                                    prepend-icon="mdi-taxi"
                                    item-value="id"
                                    outlined
                                    ref="searchDriver"
                                    @keyup.enter="actionSearch"
                                >
                                    <template v-slot:selection="data">
                                        {{ data.item.driver_number }}
                                    </template>
                                    <template v-slot:item="data">
                                        {{ data.item.driver_number }} - {{ data.item.first_name }} {{ data.item.last_name }}
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" class="d-flex align-self-center pb-0">
                                <v-btn outlined @click="resetSearch" class="ml-auto mr-2">RESETUJ</v-btn>
                                <v-btn color="primary" tile type="submit"><v-icon class="mr-1">mdi-magnify</v-icon> SZUKAJ</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>

        <v-data-table
            :headers="headers"
            :items="orders"
            :loading="loading"
            :page.sync="page"
            :options.sync="options"
            :server-items-length="totalOrders"
            class="elevation-1"
            :items-per-page="itemsPerPage"
            :footer-props="{
                itemsPerPageOptions: [25, 50, 100, 200, 500]
            }"
            @click:row="editItem"
            @page-count="pageCount = $event"
            dense
        >
            <template v-slot:top>
                <v-toolbar flat dense>
                    <v-toolbar-title class="w-100">
                        <div class="d-flex align-center">
                            Lista zleceń
                            <v-chip class="ml-auto" dark small label>ZNALEZIONO: {{ totalOrders }}</v-chip>
                        </div>
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-dialog v-model="dialogCancel" max-width="480px">
                        <v-card>
                            <v-form
                                autocomplete="off"
                                ref="form"
                                @submit.prevent="cancelItem"
                                lazy-validation
                            >
                                <v-card-title>
                                    <span class="headline">Czy na pewno chcesz anulować to zlecenie?</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-alert color="error" dense class="mt-3 text-subtitle-2">
                                        <strong>Uwaga!</strong> Jeżeli nie wybierzesz postoju, kierowca <u>nie otrzyma</u> statusu <strong>"po pudle"</strong>!
                                    </v-alert>
                                    <p>
                                        Wybierz na którym postoju będzie kierowca<br /><v-chip class="mx-1" small color="primary">{{ canceledItem.driver.driver_number }}</v-chip> -
                                        {{ canceledItem.driver.first_name }} {{ canceledItem.driver.last_name }} po <strong>niezrealizowanym</strong> zleceniu.
                                    </p>
                                    <v-autocomplete
                                        label="Postój"
                                        :items="stopsMap"
                                        v-model="canceledItem.stopIdSelect"
                                        dense
                                        item-text="name"
                                        item-value="id"
                                        required
                                        outlined
                                    />
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn color="error" tile small outlined @click="dialogCancel = false">Anuluj</v-btn>
                                    <v-btn
                                        tile
                                        small
                                        color="primary"
                                        type="submit">Potwierdzam</v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.id`]="{ item }">
                {{ item.id }}
            </template>
            <template v-slot:[`item.order_time`]="{ item }">
            <span class="d-flex flex-column align-start">
                <small class="text-no-wrap">{{ $moment.unix(item.order_time).format('YYYY-MM-DD') }}</small>
                {{ $moment.unix(item.order_time).format('HH:mm') }}
            </span>
            </template>
            <template v-slot:[`item.number`]="{ item }">
                <span class="phone-number">{{ formatNumberPhone(item.number) }}</span>
                <v-btn
                    tile
                    text
                    small
                    class="ml-2"
                    @click.stop="fastSearch('number', item)"
                >
                    <v-icon>mdi-crosshairs</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.street`]="{ item }">
                <div class="d-flex align-center">
                    <div class="text-no-wrap"><small class="mr-2">{{ item.city }}</small> <strong>{{ item.street }}</strong></div>
                    <v-btn
                        v-if="item.street"
                        tile
                        text
                        small
                        class="ml-2"
                        @click.stop="fastSearch('street', item)"
                    >
                        <v-icon>mdi-crosshairs</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.no_home`]="{ item }">
                <div class="d-flex align-center">
                    {{ item.no_home }}
                    <v-btn
                        v-if="item.no_home"
                        tile
                        text
                        small
                        class="ml-2"
                        @click.stop="fastSearch('no_home', item)"
                    >
                        <v-icon>mdi-crosshairs</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.driver.driver_number`]="{ item }">
                <v-chip class="px-2" small label color="primary">{{ item.driver.driver_number }}</v-chip>
                <v-btn
                    tile
                    text
                    small
                    class="ml-2"
                    @click.stop="fastSearch('driver', item.driver)"
                >
                    <v-icon>mdi-crosshairs</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.address`]="{ item }">
                {{ item.city }}<br />
                {{ item.street }} {{ item.no_home }}{{ (item.no_flat ? ' lok. ' + item.no_flat : null) }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <div class="d-flex" v-if="parseInt(item.status) === 1">
                    <v-btn tile text small @click.stop="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                tile
                                text
                                small
                                @click.stop="canceledItem = item;dialogCancel = true;"
                            >
                                <v-icon>mdi-cancel</v-icon>
                            </v-btn>
                        </template>
                        Anuluj to zlecenie
                    </v-tooltip>
                </div>
                <v-chip v-else-if="parseInt(item.status) === 2" small color="orange">
                    Anulowano
                </v-chip>
                <v-chip v-else small color="error">
                    Usunięto
                </v-chip>
            </template>
        </v-data-table>
        <v-pagination
            v-if="!loading"
            class="mt-2"
            v-model="page"
            :length="pageCount"
            :total-visible="15"
        ></v-pagination>
    </div>
</template>

<script>
    import Vue from "vue";

    export default {
        name: "Order",
        props: {
            listDriver: {
                type: Array,
                required: true
            },
            message: {
                type: [String, Object],
                required: false
            },
            userData: {
                type: Object,
                required: true
            },
            setCounterUpData: {
                type: Function,
                required: true
            },
            callList: {
                type: Array,
                required: true,
            },
            formatNumberPhone: {
                type: Function
            }
        },
        data: () => ({
            maxDate: new Date().toISOString().substring(0, 10),
            search: false,
            searchNumber: '',
            searchName: null,
            searchShortName: '',
            searchCity: '',
            searchStreet: '',
            searchNoHome: '',
            searchDateFrom: '',
            searchDateTo: '',
            page: 1,
            pageCount: 0,
            itemsPerPage: 100,
            dialogCancel: false,
            headers: [
                { text: 'ID', value: 'id', sortable: false },
                { text: 'Data', value: 'order_time', sortable: false },
                { text: 'Numer', value: 'number', sortable: false },
                // { text: 'Nazwa', value: 'name', sortable: false },
                { text: 'Adres', value: 'street', sortable: false },
                { text: 'Nr. domu', value: 'no_home', sortable: false },
                { text: 'Nr. lok.', value: 'no_flat', sortable: false },
                { text: 'Kierowca', value: 'driver.driver_number', sortable: false },
                { text: '', value: 'action', sortable: false },
            ],
            canceledItem: {
                stops: null,
                driver_id: null,
                driver: {
                    id: null,
                    driver_number: null,
                    first_name: null,
                    last_name: null,
                },
                stopIdSelect: null
            },
            defaultItem: {
                created: null,
                event_time: null,
                order_time: null,
                number: null,
                name: null,
                short_name: null,
                city: null,
                street: null,
                stops: null,
                no_home: null,
                no_flat: null,
                info1: null,
                driver_id: null,
            },
            options: {},
            loading: true,
            orders: [],
            totalOrders: 0,
            editedIndex: -1,
            errors: [],
            driverID: null,
        }),
        computed: {
            namesList() {return this.$store.state.socket.namesMap.all},
            cityMap() {return this.$store.state.socket.cityMap},
            streetsMap() {return this.$store.state.socket.streetsMap},
            stopsMap () {return this.$store.state.socket.stopsMap},
        },
        created() {
            this.searchDateFrom = this.userData.orderSearchDateStart ? this.$moment.unix(this.userData.orderSearchDateStart / 1000).format('YYYY-MM-DD HH:mm') : '';
        },
        watch: {
            message: function (val) {
                if(typeof val.type !== 'undefined' && val.type === 'update_required_orders') {
                    this.getDataFromApi();
                }
            },
            options: {
                handler () {
                    this.getDataFromApi();
                },
                deep: true,
            },
        },
        methods: {
            fastSearch(field, search) {
                this.resetFieldSearch();
                switch (field) {
                    case 'number':
                        this.searchNumber = search.number;
                        break;
                    case 'street':
                        this.searchCity = search.city;
                        this.searchStreet = search.street;
                        break;
                    case 'no_home':
                        this.searchCity = search.city;
                        this.searchStreet = search.street;
                        this.searchNoHome = search.no_home;
                        break;
                    case 'driver':
                        this.driverID = search.id;
                        break;
                }
                this.actionSearch();
            },
            getDataFromApi () {
                this.loading = true;
                this.getApiCell().then(data => {
                    this.orders = data.items;
                    this.totalOrders = parseInt(data.total);
                    this.itemsPerPage = data.itemsPerPage;
                    this.page = data.page;
                    this.loading = false;
                })
            },
            getApiCell () {
                this.orders = [];
                return new Promise((resolve) => {
                    let { page, itemsPerPage } = this.options;

                    if(this.search)
                        page = 1;

                    this.$http.get('p/o/l', {
                        params: {
                            per_page: itemsPerPage,
                            page: page,
                            number: this.searchNumber,
                            name: this.searchName,
                            short_name: this.searchShortName,
                            city: this.searchCity,
                            street: this.searchStreet,
                            no_home: this.searchNoHome,
                            created_from: this.searchDateFrom,
                            created_to: this.searchDateTo,
                            driver_id: this.driverID,
                        }
                    }) // Pobieranie listy zleceń
                        .then((response) => {
                            let items = response.data.data;
                            const total = parseFloat(response.data.total);
                            itemsPerPage = this.itemsPerPage;
                            page = parseFloat(response.data.current_page);

                            // if (itemsPerPage > 0) {
                            //     items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                            // }

                            resolve({
                                items,
                                total,
                                itemsPerPage,
                                page
                            });
                        })
                        .catch(() => {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Wystąpił błąd.',
                                text: 'Podczas pobierania listy zamówień.'
                            });
                        })
                        .finally(() => {
                            this.search = false;
                        });
                })
            },
            editItem (item) {
                // if(parseInt(item.status) === 1 && !this.callList.filter(( call ) => call.id === item.id).length) {
                if(parseInt(item.status) === 1 && !this.callList.filter(( call ) => call.id === item.id).length) {
                    this.$http.get('p/o/g/' + item.id) // Pobieranie zamówienia do edycji
                        .then((response) => {
                            let item = response.data;
                            let callObj = {
                                id: item.id,
                                data_type: 'edit',
                                modem_id: null,
                                operator_name: item.operator_name,
                                number: item.number,
                                caller_data: {
                                    created: item.created,
                                    event_time: item.event_time,
                                    order_time: item.order_time,
                                    number: item.number,
                                    short_name: item.short_name,
                                    name: item.name,
                                    city: item.city,
                                    street: item.street,
                                    no_home: item.no_home,
                                    no_flat: item.no_flat,
                                    info1: item.note,
                                    not_accepted_drivers: item.number_data.not_accepted_drivers,
                                },
                                driver_number: item.driver_id && this.listDriver.filter(( driver ) => driver.id === item.driver_id)[0].driver_number,
                                realizationDate: this.$moment.unix(item.event_time).format('YYYY-MM-DD'),
                                realizationTime: this.$moment.unix(item.event_time).format('HH:mm'),
                                reminder_time: 0,
                                openModal: true,
                                miniModal: false,
                                stops: item.stops
                            };
                            this.$emit('editCall', callObj);
                        })
                        .catch(() => {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Wystąpił błąd.',
                                text: 'Podczas pobierania zamówienia.'
                            });
                        });
                } else {
                    this.$emit('editCall', item);
                }
            },
            cancelItem() {
                this.$http.post('p/o/sc/' + this.canceledItem.id)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Zlecenie zostało anulowane!'
                        });
                        if (this.canceledItem.stopIdSelect) {
                            Vue.prototype.$socket.sendObj({
                                "action": "set_driver_select_first",
                                "driver_id": this.canceledItem.driver.driver_number + ':' + this.canceledItem.driver_id,
                                "stop": this.canceledItem.stopIdSelect,
                                "reason": 'po niezrealizowanym kursie'
                            });
                        }

                        this.setCounterUpData('unrealizedOrders');

                        this.dialogCancel = false;
                        const removeID = this.orders.indexOf(this.canceledItem);
                        this.orders[removeID].status = 2;
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas anulowania zlecenia.'
                        });
                    });
            },
            save () {
                if(this.$refs.form.validate()) {
                    if (this.editedIndex > -1) {
                        this.$http.post('p/o/e/' + this.editedItem.id, this.editedItem) // Edycja zlecenia
                            .then(() => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'success',
                                    text: 'Zmiany w zleceniu zostały zaktualizowane!'
                                });
                                Object.assign(this.orders[this.editedIndex], this.editedItem);
                                Vue.prototype.$socket.sendObj({action:'update_4_all_orders'});
                                this.close();
                            })
                            .catch((error) => {
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: 'Wystąpił błąd.',
                                    text: 'Zapisanie zmian w zleceniu się nie powiodło.'
                                });
                                this.errors = error.response.data.errors;
                            });
                    }
                } else {
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        text: 'Popraw dane w formularzu.'
                    });
                }
            },
            actionSearch () {
                this.$refs.searchName.isMenuActive = false
                this.$refs.searchCity.isMenuActive = false
                this.$refs.searchDriver.isMenuActive = false

                this.search = true;
                this.getDataFromApi();

                if(this.searchDateFrom) {
                    this.userData.orderSearchDateStart = Date.parse(this.searchDateFrom);
                    const parsed = JSON.stringify(this.userData);
                    localStorage.setItem('userData', parsed);
                }
            },
            resetFieldSearch() {
                this.searchNumber = '';
                this.searchName = '';
                this.searchShortName = '';
                this.searchCity = '';
                this.searchStreet = '';
                this.searchNoHome = '';
                this.searchDateFrom = '';
                this.searchDateTo = '';
                this.driverID = null;
                this.page = 0;
                this.options.page = 0;
            },
            resetSearch () {
                this.resetFieldSearch();
                this.getDataFromApi();
            },
        },
    }
</script>
